import { useParams } from 'react-router-dom';
import './header.scss';
import { AppContext } from '../AppContext';
import { useContext } from 'react';

const Header = () => {
  const { location: currentLocation } = useParams();

  const {
    context: { areas },
  } = useContext(AppContext);
  const { phone, home } = areas.find(({ slug }) => slug === currentLocation);

  return home || phone ? (
    <header className="page-header">
      <div className="container">
        <div className="page-header__inner">
          {home ? (
            <a className="header__back header__link" href={home}>
              Back to site
            </a>
          ) : (
            <span></span>
          )}

          {phone && (
            <a className="header__phone header__link" href={`tel:+1${phone}`}>
              {phone}
            </a>
          )}
        </div>
      </div>
    </header>
  ) : (
    <></>
  );
};
export default Header;
