import { useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { AppContext } from '../AppContext';

import './thanks.scss';

const ThankYou = () => {
  const { location: currentLocation } = useParams();

  const {
    context: {
      areas,
      settings: {
        thank_you: { title, subtitle, text, image },
      },
    },
  } = useContext(AppContext);
  const { home } = areas.find(({ slug }) => slug === currentLocation);

  console.log(text);
  return (
    <div className="thanks-wrapper">
      <div className="container">
        {image && <img src={image} alt="Thank you!" className="ty__image" />}
        <div className="section-title">
          <h2 className="ty__title">{title}</h2>
        </div>
        {subtitle && <p className="subtitle">{subtitle}</p>}
        {text && <p className="ty__text">{text}</p>}

        <NavLink to={home} className="button">
          Back to site
        </NavLink>
      </div>
    </div>
  );
};

export default ThankYou;
