import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';

const Day = ({ bookingData, setBookingData }) => {
  const { location: currentLocation } = useParams();
  const {
    context: { areas },
  } = useContext(AppContext);
  const { days, excludedDates } = areas.find(({ slug }) => slug === currentLocation);

  const [datesList, setDatesList] = useState();
  const [daysCount, setDaysCount] = useState(7);

  const dateRange = (startDate, endDate, steps = 1) => {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate));
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }

    return dateArray;
  };

  const handleLoadDates = () => setDaysCount(daysCount + 7);

  const handleChange = (date) => {
    setBookingData((prevState) => ({
      ...prevState,
      bookingDate: date,
    }));
  };

  const getDayName = (day) => {
    let name;

    switch (day) {
      case 1:
        name = 'Monday';
        break;
      case 2:
        name = 'Tuesday';
        break;
      case 3:
        name = 'Wednesday';
        break;
      case 4:
        name = 'Thursday';
        break;
      case 5:
        name = 'Friday';
        break;
      case 6:
        name = 'Saturday';
        break;
      default:
        name = 'Sunday';
        break;
    }

    return name;
  };

  const isAvailableDay = (date) =>
    (!days.sunday && date.day() === 0) ||
    (!days.monday && date.day() === 1) ||
    (!days.tuesday && date.day() === 2) ||
    (!days.wednesday && date.day() === 3) ||
    (!days.thursday && date.day() === 4) ||
    (!days.friday && date.day() === 5) ||
    (!days.saturday && date.day() === 6);

  const isNotExcludedDay = (date) => {
    const date_to_check = moment(new Date(date)).format('LL');
    const excluded_days = [];

    if (excludedDates)
      excludedDates?.forEach((el) => {
        excluded_days.push(moment(el.date).format('LL'));
      });

    return excluded_days.includes(date_to_check);
  };

  useEffect(() => {
    const today = moment().endOf('day').format('LL');

    const notToday = moment(today).add(1, 'y').format('LL');

    const dates = dateRange(today, notToday);

    setDatesList(dates);
  }, []);

  return (
    <fieldset className="step-wrapper form__grid">
      {datesList?.slice(0, daysCount).map((el, index) => (
        <div
          key={index}
          className={`date-wrapper ${
            moment(el).format('LL') === moment(bookingData.bookingDate).format('LL') ? 'active' : 'inactive'
          }`}
          aria-disabled={
            isAvailableDay(moment(el)) ||
            isNotExcludedDay(moment(el)) ||
            (moment().format('LL') === moment(el).format('LL') &&
              moment().startOf('day').add(12, 'hour').format('h:mm:ss a') < moment().format('h:mm:ss a'))
          }
          onClick={() => handleChange(moment(el).format())}
        >
          <span>
            {moment(el).format('LL')} ({getDayName(moment(el).day())})
          </span>
        </div>
      ))}

      {daysCount <= datesList?.length && (
        <span onClick={handleLoadDates} className="load-more-btn">
          Load more
        </span>
      )}
    </fieldset>
  );
};

export default Day;
