import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { AppContext } from '../../AppContext';

const Service = ({ bookingData, setBookingData }) => {
  const { location: currentLocation } = useParams();
  const {
    context: { areas },
  } = useContext(AppContext);
  const { services } = areas.find(({ slug }) => slug === currentLocation);

  const handleChange = (service) => {
    setBookingData((prevState) => ({
      ...prevState,
      bookingDetails: {
        ...prevState.bookingDetails,
        type_of_service: service,
      },
    }));
  };
  return (
    <fieldset className="inputs-wrapper step-wrapper form__grid form__grid_two">
      {services
        ?.filter(({ title }) => !/^appliance/i.test(title))
        ?.sort(({ title: a }, { title: b }) => a.localeCompare(b))
        ?.map(({ title, description }, index) => (
          <div
            key={index}
            className={`service-wrapper ${
              title.includes(bookingData?.bookingDetails?.type_of_service) ? 'active' : 'inactive'
            }`}
            onClick={() => handleChange(title)}
          >
            <span>{title}</span>
            {description ?? <p>{description}</p>}
          </div>
        ))}
    </fieldset>
  );
};

export default Service;
