import { useContext } from 'react';
import { AppContext } from '../../AppContext';

const Brand = ({ bookingData, setBookingData }) => {
  const {
    context: {
      settings: { brands },
    },
  } = useContext(AppContext);

  const handleChange = (brand) => {
    setBookingData((prevState) => ({
      ...prevState,
      bookingDetails: {
        ...prevState.bookingDetails,
        brand,
      },
    }));
  };

  return (
    <fieldset className="step-wrapper form__grid form__grid_two">
      {brands
        ?.sort(({ name: a }, { name: b }) => a.localeCompare(b))
        ?.map(({ name }, index) => (
          <div
            key={index}
            className={`brand-wrapper ${name === bookingData.bookingDetails.brand ? 'active' : 'inactive'}`}
            onClick={() => handleChange(name)}
          >
            <span>{name}</span>
          </div>
        ))}
    </fieldset>
  );
};

export default Brand;
